import React from 'react';
import { useEffect, useState } from 'react';

import FeedbackCsat from 'components/features/FeedbackCsat';
import useMediaQuery from 'hooks/useMediaQuery';
import { getSettings } from 'utils/extension';

import { PlaybackControls } from '../../ReadingInfo';

const FEEDBACK_LEVELS_WORD_QUOTA = 500;

export const useFeedback = (playbackControls?: PlaybackControls, showReferral: () => void = () => {}) => {
  const { matches: isDesktop } = useMediaQuery('(min-width: 900px)');

  const [closedFeedback, setClosedFeedback] = useState<boolean>(false);
  const [feedbackShown, setFeedbackShown] = useState<boolean>(true);
  const [progressFraction, setProgressFraction] = useState(0);
  const [wordCount, setWordCount] = useState(0);

  const setFeedbackSeen = async () => {
    const extension = await getSettings();

    let seen = false;

    if (extension?.hasSeenFeedbackCsat) {
      seen = true;
    }

    if (localStorage.getItem('feedbackShown')) {
      seen = true;
    }

    setFeedbackShown(seen);
  };

  useEffect(() => {
    setFeedbackSeen();
    setWordCount(parseInt((localStorage.getItem('currentWordCount') || 0) + '', 10));

    if (playbackControls) {
      playbackControls.addListener(state => setProgressFraction(state.progressFraction));
    }
    // ESLint: React Hook useEffect has a missing dependency: 'playbackControls'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseFeedback = (closed: boolean) => {
    setClosedFeedback(closed);
  };

  const showingFeedback = progressFraction > 0.95 && !feedbackShown && !closedFeedback && wordCount > FEEDBACK_LEVELS_WORD_QUOTA;

  const FeedbackExperience = () => (
    <>
      {showingFeedback && (
        <div style={{ width: isDesktop ? '15%' : '50%' }}>
          <FeedbackCsat handleClose={handleCloseFeedback} showReferral={showReferral}></FeedbackCsat>
        </div>
      )}
    </>
  );

  const showingLibraryFeedback = !feedbackShown && !closedFeedback && wordCount > FEEDBACK_LEVELS_WORD_QUOTA;

  const FeedbackLibraryExperience = () => (
    <>{showingLibraryFeedback && <FeedbackCsat handleClose={handleCloseFeedback} showReferral={showReferral}></FeedbackCsat>}</>
  );

  return { FeedbackExperience, FeedbackLibraryExperience, showingFeedback, showingLibraryFeedback };
};
