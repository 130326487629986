import React, { FormEvent, MouseEvent, useEffect, useState } from 'react';

import Collaborate from 'assets/icons/collaborate';
import { Cross } from 'assets/icons/cross';
import useMediaQuery from 'hooks/useMediaQuery';
import useTranslation from 'next-translate/useTranslation';
import { twMerge } from 'tailwind-merge';
import { logSegmentEvent } from 'utils/analytics';
import { checkExtensionInstalled, setFeedbackSeen } from 'utils/extension';

type Option = {
  value: number;
  emoji: string;
  checked: boolean;
};

const options: Option[] = [
  { value: 1, emoji: '😟', checked: false },
  { value: 2, emoji: '🙁', checked: false },
  { value: 3, emoji: '😐', checked: false },
  { value: 4, emoji: '🙂', checked: false },
  { value: 5, emoji: '😍', checked: false }
];

export type FeedbackCsatProps = {
  showReferral: () => void;
  handleClose: (args: boolean) => void;
};

const setExtensionFeedbackSeen = async () => {
  const isInstalled = await checkExtensionInstalled();

  if (isInstalled) {
    setFeedbackSeen(true);
  }
};

const FeedbackCsat = ({ handleClose, showReferral }: FeedbackCsatProps) => {
  const { t } = useTranslation('common');

  // state
  const [sentiment, setSentiment] = useState(null);
  const [feedbackText, setFeedbackText] = useState(null);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);

  const { matches: isDesktop } = useMediaQuery('(min-width: 900px');

  useEffect(() => {
    localStorage.setItem('feedbackShown', 'true');
    setExtensionFeedbackSeen();
  }, []);

  const handleCloseClick = () => {
    handleClose(true);
  };

  const handleFeedbackChange = (e: FormEvent<HTMLTextAreaElement>) => {
    e.currentTarget.classList.add('speechify-ignore-keybindings');
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setFeedbackText(e.currentTarget.value);
  };

  const handleFeedbackSubmit = () => {
    setFeedbackSubmitted(true);
    logSegmentEvent('listening_feedback_content', { sentiment: sentiment, feedback: feedbackText, isNewListeningExperience: true });

    // @ts-expect-error TS(2531): Object is possibly 'null'.
    if (sentiment >= 4) {
      handleClose(true);

      if (showReferral) {
        showReferral();
      }
    }
  };

  const handleOptionClick = (option: Option, e: MouseEvent<HTMLInputElement>) => {
    // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    setSentiment(e.currentTarget.value);
    logSegmentEvent('listening_feedback_rating', { sentiment: e.currentTarget.value, isNewListeningExperience: true });
    option.checked = true;
  };

  return (
    <div className="z-[100]">
      <div
        className={twMerge(
          'align-middle',
          'rounded-lg',
          'w-60',
          'bg-glass-0',
          'dark:bg-glass-700',
          'border-[0.5px]',
          'border-solid',
          'border-glass-300',
          'dark:border-glass-600',
          'relative',
          'bottom-8'
        )}
      >
        <div className="mr-2 mt-2 flex justify-end">
          <Cross className="cursor-pointer text-secondaryTint dark:text-glass-350" onClick={handleCloseClick} />
        </div>
        {!feedbackSubmitted ? (
          <div>
            <div className="mb-3 align-middle" style={{ display: 'flex', justifyContent: 'center' }} onClick={e => e.stopPropagation()}>
              <h3 className="align-middle text-glass-700 dark:text-glass-0">
                {' '}
                {t('How would you rate your')} <br />
                {t(' listening experience so far?')}{' '}
              </h3>
            </div>
            <div className="mb-3" style={{ display: 'flex', justifyContent: 'space-evenly' }}>
              {options.map(option => (
                <label
                  key={`option-${option.value}`}
                  className={twMerge(
                    'flex',
                    'h-7',
                    'w-7',
                    'cursor-pointer',
                    'items-center',
                    'justify-center',
                    'rounded-full',
                    'p-1',
                    'checked:bg-electric-200',
                    'hover:bg-electric-200',
                    'active:bg-electric-200',
                    'dark:checked:bg-electric-500',
                    'dark:hover:bg-electric-500',
                    'dark:active:bg-electric-500'
                  )}
                >
                  <input className="hidden" type="radio" name="radio" value={option.value} onClick={handleOptionClick.bind(this, option)} />
                  <span>{option.emoji}</span>
                </label>
              ))}
            </div>
            {sentiment !== null ? (
              <div>
                <div className="px-4 pb-4 text-right">
                  <textarea
                    className={twMerge(
                      'w-full',
                      'mb-1',
                      'text-sm',
                      'border-none',
                      'rounded-md',
                      'h-28',
                      'bg-glass-200',
                      'dark:bg-glass-800',
                      'dark:border',
                      'dark:border-solid',
                      'dark:border-glass-600',
                      'dark:text-glass-0',
                      'dark:placeholder-glass-400'
                    )}
                    onChange={handleFeedbackChange}
                    placeholder={t('How would you rate your') + t(' listening experience so far?')}
                  />
                  <button
                    className="rounded-md bg-blue-700 px-3 py-1 text-center text-glass-0 disabled:bg-glass-300 disabled:text-glass-400 dark:bg-electric-300 dark:text-glass-700"
                    disabled={!feedbackText}
                    onClick={handleFeedbackSubmit}
                  >
                    {t('Submit')}
                  </button>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div className="align-middle">
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Collaborate />
            </div>

            {sentiment === '5' ? (
              <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '8%' }}>
                <h3>
                  <span style={{ marginLeft: '5%' }}> {t('Thanks ❤ Glad you’re')} </span>
                  <br />
                  {t('enjoying Speechify so far')}
                </h3>
              </div>
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', marginLeft: '6%', marginBottom: '8%' }}>
                <h3>
                  {t(' Thanks ❤ We truly appreciate your feedback,and will pass this')}{' '}
                  <span style={{ marginLeft: isDesktop ? '10%' : '1%' }}>{t('along to our developers')}</span>
                </h3>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FeedbackCsat;
