import { useCallback, useEffect, useState } from 'react';

import { hideIntercom, showIntercom } from 'utils/intercom';

function usePreferenceVariable(name: string, initValue: $TSFixMe) {
  const [ready, setReady] = useState(false);
  const [value, setValue] = useState(initValue);

  // get value on bootup
  useEffect(() => {
    const storageValue = localStorage.getItem(name);

    if (storageValue === null) {
      localStorage.setItem(name, initValue);
    } else {
      setValue(storageValue === 'true');
    }

    setReady(true);
    // ESLint: React Hook useEffect has missing dependencies: 'initValue' and 'name'. Either include them or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateValue = useCallback((val: $TSFixMe) => {
    localStorage.setItem(name, String(val));
    setValue(val);

    window.dispatchEvent(new Event('preference'));

    // toggle intercom
    if (name === 'hidecontrols') {
      if (String(val) === 'true') {
        hideIntercom();
      } else {
        showIntercom();
      }
    }
    // ESLint: React Hook useCallback has a missing dependency: 'name'. Either include it or remove the dependency array.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    value,
    updateValue,
    ready
  };
}

export default usePreferenceVariable;
