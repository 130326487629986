import React from 'react';
import { useCallback, useMemo } from 'react';

import { useTranslation } from 'hooks/useTypedTranslation';
import { useDispatch, useSelector } from 'store';
import { autoScrollFullStateSelector, preferencesActions } from 'store/preferences';

export const AutoScrollButton = () => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const { autoScroll, autoScrollTemporary } = useSelector(autoScrollFullStateSelector);

  const enableAutoScroll = useCallback(() => {
    dispatch(preferencesActions.setAutoScrollTemporary(true));
  }, [dispatch]);

  const shouldRenderEnableAutoScrollButton = useMemo(() => {
    const state = autoScroll && !autoScrollTemporary;
    return state;
  }, [autoScroll, autoScrollTemporary]);

  if (shouldRenderEnableAutoScrollButton) {
    return (
      <div className="pointer-events-auto fixed bottom-8 left-[calc(50%+180px)] z-10 h-9 w-40 overflow-hidden">
        <button className="h-full w-full rounded-lg bg-glass-700 font-ABCDiatype text-sm text-white" onClick={enableAutoScroll}>
          {t('Re-enable Auto Scroll')}
        </button>
      </div>
    );
  }
  return null;
};
